@use 'rem' as *;
@use 'breakpoints' as b;

@mixin defaultTypo () {
    font-weight: 400;
    letter-spacing: rem-calc(0.84);
    font-size: rem-calc(16); 
    line-height: 1.4;
}

@mixin buttonTypo () {
    font-weight: 400;
    font-size: rem-calc(16);
    line-height: rem-calc(16);  
    letter-spacing: rem-calc(2.08);
}

@mixin rteTypo () {
    font-size: rem-calc(20);
    line-height: rem-calc(26);

    @include b.mq-up(l) {
        font-size: rem-calc(28);
        line-height: rem-calc(34);
    }
}

@mixin defaultBig( ) {
    line-height: rem-calc(19);

    @include b.mq-up(l) {
        font-size: rem-calc(28);
        line-height: rem-calc(34);
    }
}

@mixin headlineTypo () {
    font-size: rem-calc(25);
    line-height: rem-calc(30);
    letter-spacing: .2rem;

    @include b.mq-up(l) {
        font-size: rem-calc(38);
        line-height: rem-calc(44);
    }
}

@mixin headlineLetterSpacingTypo () {
    letter-spacing: rem-calc(3.25);

    @include b.mq-up(l) {
        letter-spacing: rem-calc(5.07);
    }
}

@mixin headlineHeightTypo () {
    line-height: rem-calc(25);

    @include b.mq-up(l) {
        line-height: rem-calc(45);
    }
}

@mixin headlineBigTypo () {
    font-weight: 700;
    text-transform: lowercase;
    font-size: rem-calc(101);
    line-height: rem-calc(93);
    
    @include b.mq-up(l) {
        font-size: rem-calc(160);
        line-height: rem-calc(160);
    }
}

@mixin headlineBigAltTypo () {
    
    @include b.mq-up(l) {
        font-size: rem-calc(170);
        line-height: rem-calc(170);
    }
}

@mixin footerHeadlineType () {
    line-height: rem-calc(42);
    font-size: rem-calc(40);
}

@mixin cropText($rows: 1) {
    overflow: hidden;
    text-overflow: ellipsis;

    @if ($rows == 1) {
        white-space: nowrap;
    } 

    @else {
        display: -webkit-box;
        -webkit-line-clamp: $rows;
        -webkit-box-orient: vertical;
    }
}