@use '../abstracts' as *;

#{$mark}footer {
    background-color: var(--bgColor-footer);
    background: var(--gradient-footer);
    color: var(--color-footer);
    padding-block: $margin-fixed;
    position: relative;
    
    @include mq-up(l) {
        padding-block: rem-calc(0 12);
    }

    #{$mark}container {
        
        @include mq-up(l) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    #{$mark}logo {
        margin-bottom: rem-calc(43);

        @include mq-up(l) {
            margin-top: rem-calc(52);
            width: rem-calc(246);
        }
    }

    &__contact {
        font-weight: 700;
        font-size: rem-calc(18);
        color: var(--color-footer-contact);

        @include mq-up(l) {
            width: rem-calc(300);
        }
    }

    &__text {
        @include headlineBigTypo;
        margin-bottom: rem-calc(22);

        @include mq-up(l) {
            font-size: rem-calc(80);
            line-height: rem-calc(80);
            width: 62%;
            margin-top: rem-calc(-10);
        }

        p {
            margin: 0;
        }
    }

    &__service {
        
        @include mq-up(l) {
            width: 100%;
        }

        #{$mark}menu--service {

            @include mq-up(l) {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            li {
                &:first-child {
                    
                    @include mq-up(l) {
                        width: rem-calc(300);
                    }
                }

                &:nth-child(2) {
                    
                    @include mq-up(l) {
                        width: 62%;
                    }

                    #{$mark}menu__link {
                        
                        @include mq-up(l) {
                            padding-left: rem-calc(6);
                        }
                    }
                }
            }
        }
    }
}
