@use '../abstracts' as *;

#{$mark}facts {
    display: flex;
    flex-wrap: wrap;
    margin-block: $margin-fixed;
    column-gap: rem-calc(10);
    letter-spacing: .13rem;

    &__item {
        margin: 0;
        display: grid;
        grid-template-columns: auto auto;
        column-gap: rem-calc(10);

        span {
            color: var(--facts-color);
            display: inline-block;
        }
    }

    &__break {
        width: 100%;
        height: 0;
    }
}