@use '../abstracts' as *;

#{$mark}header {
    background-color: var(--bgColor-header);
    color: var(--color-header);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;

    #{$mark}container {
        padding-block: rem-calc(23 28);
    

        @include mq-up(l) {
            padding-block: rem-calc(16 22);
            display: grid;
            grid-template-columns: auto auto;
            justify-content: space-between;
        }
    }

    &__logo {
        display: flex;
        
        @include mq-to(l) {
            margin-bottom: rem-calc(19);
        }

        #{$mark}logo:hover {
            background-image: url('../../images/logo-light.svg');
        }
    }

    &__menu {
        color: var(--color-alt-header);
        
        @include mq-to(l) {
            display: none;
        }
        @include mq-up(l) {
            align-self: center;
        }
    }

    &__i18n {

        @include mq-to(l) {
            display: none;
        }
        
        @include mq-up(l) {
            justify-self: flex-end;
            align-self: flex-end;
            transform: translateY(-18px);
        }
    }

    &__actions {
        display: flex;
        gap: rem-calc(18);

        @include mq-up(l) {
            transform: translateY(-12px);
        }

        #{$mark}button {
            overflow: hidden;
            white-space: nowrap;
        }
    }
}