@use '../abstracts' as *;

#{$mark}latest-cards {
    margin-top: $margin-large-mob;
    
    @include mq-up(l) {
        margin-top: $margin-large-desk;
    }

    #{$mark}tiles {
        margin-top: $margin-small-mob;
    
        @include mq-up(l) {
            margin-top: $margin-small-desk;
        }
    }
}