@use '../abstracts' as *;

// DARK HEADER - WHITE BODY

#{$mark}theme--main {
    --bgColor: var(--color-50);
    --gradient: var(--color-50);
    --color: var(--color-350);

    // HEADER
    --bgColor-header: var(--color-200);

    // MENU
    --menu-active-color: var(--color-350);

    //I18N
    --color-active-i18n: var(--color-350); 
 

    // TITLE
    --color-title: var(--color-200);
    --color-headline: var(--color-350);

    // NEWS
    --color-news: var(--color-150);
    --bgColor-news: var(--color-500);
}

//LIGHT HEADER - WHITE BODY

#{$mark}theme--main-dark {
    --bgColor: var(--color-50);
    --gradient: var(--color-50);
    --color: var(--color-350);

    // TITLE
    --color-title: var(--color-200);
    --color-headline: var(--color-350);


    // NEWS
    --color-news: var(--color-150);
    --bgColor-news: var(--color-500);
}