@keyframes reveal {
    from {
        filter: blur(14px);
        opacity: 0;
    }

    to {
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes revealDelay {
    0% {
        filter: blur(14px);
        opacity: 0;
    }   

    40% {
        filter: blur(14px);
        opacity: 0;
    }
    100% {
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes showElement {
    from {
        filter: blur(9px);
    }

    to {
        filter: blur(0);
    }
}