@use '../abstracts' as *;

$image-width: rem-calc(287);

#{$mark}teaser {
    
    @include mq-up(l) {
        display: grid;
        gap: 1.5rem;
        grid-template-columns: auto $image-width;
    }

    // &__details {}

    &__header {
        margin-bottom: $margin-small;
    }

    &__intro {
        margin: 0;
        @include rteTypo;
    }

    &__actions {
        margin-top: $margin-small;
        display: flex;
        gap: rem-calc(27);

        #{$mark}button {

            @include mq-to(l) {
                width: 100%;
                text-align: center;
            }

            &--alt {

                @include mq-to(l) {
                    display: none;
                }
            }
        }
    }

    &__media {
        overflow: hidden;
        border-radius: 50%;
        width: $image-width;
        aspect-ratio: 1;
        min-width: $image-width;
        
        @include mq-to(l) {
            display: none;
        }

        #{$mark}video,
        #{$mark}media__image {
            object-fit: cover;
        }

        #{$mark}video,
        #{$mark}media {
            height: 100%;
            margin: 0;
        }
    }
}
