@use '../abstracts' as *;

#{$mark}news {
    
    &__list {
        margin-bottom: rem-calc(40);

        #{$mark}media {
            
            @include mq-up(l) {
                height: 100%;
                max-height: rem-calc(179);
            }
        }
    }

    &__grid {
        
        @include mq-up(l) {
            grid-template-columns: 1fr 1fr;
            display: grid;
            gap: $margin-fixed;
        }

        #{$mark}media {
            margin: 0;
        }
    }

    &__details {
        background-color: var(--news-bgColor);
        color: var(--news-color);
        @include rteTypo;
        transition-property: background-color color;
        transition-duration: 200ms;
        transition-timing-function: ease-in-out;

        @include mq-up(l) {
            padding: rem-calc(0 0 10);
        }
    }

    &__header {
        display: flex;
        flex-direction: column-reverse;
        
        #{$mark}title {
            text-decoration: underline;
            color: var(--news-color);
            line-height: rem-calc(25);
            font-weight: 400;
            transition: color ease-in-out 200ms;
            @include headlineLetterSpacingTypo;

            &:not(#{$mark}news--preview &) {

                @include mq-up(l) {
                    @include cropText(3);
                    height: rem-calc(135);
                    line-height: rem-calc(45);
                }
            }
        }
    }

    &__eyebrow {
        font-weight: 700;
    }

    // &__description {}

    #{$mark}media {
        
        #{$mark}news--teaser & {
            width: 100%;
            height: 100%;
        }

        &__image {

            #{$mark}news--teaser & {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &-latest {
        margin-top: $margin-large-mob;
    
        @include mq-up(l) {
            margin-top: $margin-large-desk;
            position: relative;
        }

        #{$mark}news__link {
            display: inline-block;
            margin-top: $margin-small-mob;
            
            @include mq-up(l) {
                margin-top: $margin-small-desk;
            }
        }

        #{$mark}news__details {
            background: transparent;
            position: relative;

            &:hover {
                
                color: var(--news-hover-color);
                
                &:before {
                    background-color: var(--news-hover-bgColor);
                }
                    
                #{$mark}title {
                    color: var(--news-hover-color);
                }
            }

            &:before {
                background-color: var(--news-bgColor);
                transition: background-color ease-in-out 200ms;
                content: '';
                display: inline-block;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                position: absolute;
                z-index: 1;
            }

            > * {
                z-index: 2;
                position: relative;
            }

        }

        #{$mark}media {
            margin-bottom: $margin-small-mob;

            @include mq-up(l) {
                margin-bottom: $margin-small-desk;
            }
        }

        #{$mark}button {
            @include mq-up(l) {
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }

        +#{$mark}content--media {
            margin-top: $margin-large-mob;

            @include mq-up(l) {
                margin-top: $margin-large-desk;
            }
        }
    }

    &__item {
        #{$mark}news__link {
            &:hover {
                
                #{$mark}news__details {
                    background-color: var(--news-hover-bgColor);
                    color: var(--news-hover-color);
                }
                
                #{$mark}title {
                    color: var(--news-hover-color);
                }
            }
        }
    }

    &--preview {
        align-items: center;

        #{$mark}title {
            @include headlineHeightTypo;
        }
    }
}