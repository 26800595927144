@use 'rem' as *;

$mark: '.bb-';
$margin-small: rem-calc(13);
$margin-fixed: rem-calc(19);
$margin-small-mob: rem-calc(29);
$margin-medium-mob: rem-calc(83);
$margin-large-mob: rem-calc(65);
$margin-small-desk: rem-calc(48);
$margin-medium-desk: rem-calc(40);
$margin-large-desk: rem-calc(130);