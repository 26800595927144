@use '../abstracts' as *;

html {
    scroll-behavior: smooth;
    scroll-padding-block-start: rem-calc(190);

    @include mq-up(l) {
        scroll-padding-block-start: rem-calc(170);
    }
}

#{$mark}main {
    background-color: var(--bgColor);
    padding-top: rem-calc(181);

    @include mq-up(l) {
        padding-top: rem-calc(149);
    }
}

#{$mark}wrapper {
    background-color: var(--bgColor);
    background: var(--gradient);
    color: var(--color);
    padding-block: $margin-fixed $margin-medium-mob;

    @include mq-up(l) {
        padding-bottom: $margin-medium-desk;
    }

    > #{$mark}container {

        >:first-child {
            &:not(#{$mark}button--back) {
                margin-top: 0;
            }
        }

        >:last-child {
            margin-bottom: 0;
        }
    }
}

#{$mark}container {
    max-width: rem-calc(1429);
    margin-inline: auto;
    padding-inline: rem-calc(16);

    @include mq-up(m) {
        padding-inline: rem-calc(28);
    }

    @include mq-up(l) {
        padding-inline: rem-calc(80);
    }
} 

#{$mark}content--media {
    margin-block: $margin-medium-mob $margin-small-mob;

    @include mq-up(l) {
        margin-block: $margin-medium-desk $margin-small-desk;
    }

    #{$mark}media,
    #{$mark}video {
        margin: 0;
    }

    + #{$mark}latest-cards {
        margin-top: $margin-small-mob;

        @include mq-up(l) {
            margin-top: $margin-medium-desk;
        }
    }
}

#{$mark}hidden {
    display: none;
}

#{$mark}headline {
    margin-bottom: $margin-fixed;
    
    + #{$mark}content--media {
        margin-block: $margin-fixed;
    }
}

#{$mark}media--no-image {
    background-color: lightgreen;
    display: inline-block;
    aspect-ratio: 16/10;
    width: 100%;
}

video {
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
    display: block;
}

#{$mark}matterport {
    margin-block: $margin-fixed;
}