@use '../abstracts' as *;

#{$mark}grid {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    row-gap: $margin-small-mob;

    @include mq-up(l) {
        column-gap: $margin-fixed;
    }

    &#{$mark}reverse {

        @include mq-up(l) {
            flex-direction: row-reverse;
        }
    }

    &__item {
        width: 100%;

        @include mq-up(l) {
            width: calc(50% - 18.5px);
        }

        &--action {
            display: flex;
            align-items: flex-end;

            #{$mark}right & {
                justify-content: flex-end;

                #{$mark}button--group {
                    align-items: flex-end;
                }
            }
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }
}