$breakpoints: (
    'm':       40rem, //640
    'l':       64rem, //1024
    'xl':      75rem, //1200
    '2xl':     87.5rem, //1400
) !default;

@mixin mq-up($breakpoint) {

    @if map-has-key($breakpoints, $breakpoint) {
        $breakpoint-value: map-get($breakpoints, $breakpoint);
        $breakpoint-value: $breakpoint-value;
        $query: "(min-width: #{$breakpoint-value})";
        @media #{$query} { @content; }
    }

    @else {
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

@mixin mq-to($breakpoint) {

    @if map-has-key($breakpoints, $breakpoint) {
        $breakpoint-value: map-get($breakpoints, $breakpoint);
        $breakpoint-value: $breakpoint-value - .06rem;
        $query: "(max-width: #{$breakpoint-value})";
        @media #{$query} { @content; }
    }

    @else {
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}
