@use '../abstracts' as *;

#{$mark}mosaic {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: 'big';
    gap: rem-calc(24);

    &:not(:last-child) {
        margin-bottom: rem-calc(24);
    }

    &--2, 
    &--3, 
    &--4 {
        grid-template-columns: 2fr 1fr;

        &#{$mark}mosaic--reverse {
            grid-template-columns: 1fr 2fr;
        } 
    }

    &--2 {
        grid-template-areas: 
        'big top';

        &#{$mark}mosaic--reverse {
            grid-template-areas: 
            'top big';
        }
    }

    &--3 {
        grid-template-rows: 1fr 1fr;
        grid-template-areas: 
        'big top'
        'big middle';

        &#{$mark}mosaic--reverse {
            grid-template-areas: 
            'top big'
            'middle big';
        }
    }

    &--4 {
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-areas:
        'big top'
        'big middle'
        'big bottom';

        &#{$mark}mosaic--reverse {
            grid-template-areas: 
            'top big'
            'middle big'
            'bottom big';
        }
    }

    #{$mark}media {
        margin: 0;

        &--big {
            grid-area: big;
        }

        &--top {
            grid-area: top;
        }

        &--middle {
            grid-area: middle;
        }

        &--bottom {
            grid-area: bottom;
        }
    }
}