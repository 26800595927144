@use '../abstracts' as *;

.is-blured {
    filter: blur(14px);
}

.is-revealed {
    animation-duration: 1500ms;
    animation-timing-function: ease-in-out;
    animation-name: showElement;
}

[data-reveal] {

    > *:not(.animation-in) {
        opacity: 0;
    }
    
    span {
        opacity: 0;
        filter: blur(14px);
        transition-property: opacity filter;
        transition-duration: 1000ms;
        transition-timing-function: ease-in-out;

        &.animation-in {
            opacity: 1;
            filter: blur(0);
        }

    }
}
