@use '../abstracts' as *;

#{$mark}hero, 
#{$mark}hero--alt {

    #{$mark}title--highlight {
        color: var(--color-hero);
        margin-top: rem-calc(-9);
        margin-bottom: $margin-fixed;
        
        @include mq-up(l) {
            margin-top: rem-calc(-19);
            font-size: rem-calc(220);
            line-height: rem-calc(203);
        }
    }
}

#{$mark}hero {
    
    &:not(&#{$mark}hero--special) {
        position: relative;

        @include mq-to(m) {
            aspect-ratio: 16/9;
        }
        
        @include mq-up(m) {
            min-height: calc(100dvh - 172px);
        }
       
        @include mq-up(l) {
            min-height: calc(100dvh - 130px);
        }
        
        #{$mark}container {
            position: relative;
            z-index: 1;
        }
    }
   
    &__media {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0;

        #{$mark}media {
            height: 100%;
            width: 100%;
            margin: 0;
            
            &__image {
                object-fit: cover;
            }
        } 

        #{$mark}consentbanner-placeholder {
            height: 100%;
        }

        video {
            height: 100%;
        }

        iframe {
            width: 100%;
            border: none;
            height: 100%;
        }
    }

    &--special {
        color: var(--color-hero);
        padding-bottom: $margin-fixed;
        margin-bottom: $margin-large-mob;

        @include mq-up(l) {
            margin-bottom: $margin-medium-desk;
        }

        #{$mark}container:first-child {
            min-height: 100dvh;
            position: relative;
            z-index: 1;
        }

        #{$mark}title--highlight {
            color: var(--color-hero);
    
            P {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                #{$mark}width-big {
                    width: 100%;
                }
                    
                #{$mark}width-small {
                    width: auto;
                }
            }
        }

        #{$mark}textarea-btn {
            margin-block: $margin-fixed 0;
        }
    }
}