@use '../abstracts' as *;

#{$mark}textarea {

    &-cols {
        margin-block: $margin-fixed;

        &__grid {

            @include mq-up(l) {
                display: flex;
                margin: rem-calc(-10);
            }
        }

        #{$mark}col {
            
            @include mq-up(l) {
                width: 50%;
                padding: rem-calc(10);
            }

            &:not(&:last-child) {

                @include mq-to(l) {
                    margin-bottom: $margin-small-desk;
                }
            }

            #{$mark}media {
                margin-top: $margin-fixed;
            }
        }
    }

    &-btn {
        margin-block: $margin-medium-desk $margin-large-mob;

        #{$mark}grid__item--action {
            @include mq-to(l) {
                display: block;
            }
        }
    }
}

