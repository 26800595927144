@use '../abstracts' as *;

.is-canvas {
    overflow: hidden;
}

#{$mark}canvas {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    transform: translateX(-130%);
    bottom: 0;
    z-index: 99;
    background-color: var(--canvas-bgColor);
    background: var(--canvas-gradient);
    color: var(--canvas-color);
    z-index: 99;
    padding-block: rem-calc(24);
    
    .is-open & {
        transform: translateX(0);
    }
    
    &__btn {
        border: none;
        padding: 0;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        
        &-title {
            display: none;
        }
        
        &-icon {    
            display: flex;
            flex-direction: column;
            gap: rem-calc(10);

            @include mq-up(m) {
                margin-right: rem-calc(18);
            }

            &:before,
            &:after {
                width: rem-calc(60);
                height: rem-calc(9);
                background-color: var(--canvas-button-color);
                content: '';
                display: inline-block;

                @include mq-up(m) {
                    width: rem-calc(96);
                }
            }
        }

        &--close {
            cursor: pointer;
            background-color: transparent;
            border: none;
            line-height: rem-calc(90);
            height: rem-calc(80);
            margin: -1rem 0 0 auto;
            padding: 0;

            #{$mark}canvas__btn-title {
                display: block;
                font-size: rem-calc(110);
                color: var(--canvas-color);
            }
        }
    }

    &__inner {
        height: 100%;
        overflow-y: auto;
        padding-inline: rem-calc(16);

        @include mq-up(m) {
            padding-inline: rem-calc(28);
        }
    }
  
    &__nav {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    #{$mark}header__logo {
        margin: 0;
    }
    
    #{$mark}logo {
        background-image: url('../../images/logo-dark.svg');
    }

    &__i18n {
        &#{$mark}menu {

            &--i18n {
                margin-bottom: $margin-fixed;
                margin-top: rem-calc(-20);
                transform: translateY(-100%);
                width: rem-calc(90);
            }

            span {
                color: var(--canvas-menu-active-color);
            }
        }
    }

    &__menu {
        &#{$mark}menu {
            display: flex;
            flex-direction: column;
            font-size: rem-calc(39);
            gap: rem-calc(16);
            margin-top: rem-calc(-64);
            
            @include mq-up(m) {
                gap: $margin-fixed;
            }

            #{$mark}menu__link {
                display: block;

                &#{$mark}active {
                    color: var(--canvas-menu-active-color);
                }
            }
        }
    }
}
