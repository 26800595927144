@use '../abstracts' as *;

body {
    @include defaultTypo;
    word-break: break-word;
    hyphens: auto;
    font-family: "BP-Theinhardt";
}

#{$mark}card #{$mark}title, 
#{$mark}news__header #{$mark}title, 
#{$mark}headline #{$mark}title, 
#{$mark}teaser__header #{$mark}title, 
#{$mark}grid__item #{$mark}title, 
#{$mark}button,
#{$mark}menu--i18n,
#{$mark}clouds,
#{$mark}facts,
#{$mark}canvas,
#{$mark}canvas__btn-title {
    font-family: 'NB-Architekt';
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
} 

b, strong {
    font-weight: 700;
}

#{$mark}title {
    color: var(--color-title);
    @include headlineTypo;

    &--highlight {
        color: var(--color-headline);
        @include headlineBigTypo;

        p {
            margin: 0;
        }
    }

    &--line {
       margin-left: -.625rem;
    }
}