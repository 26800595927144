* {
    box-sizing: border-box;
    margin: 0;
}

blockquote, figcaption, figure, ol, p, ul, address {
    margin-bottom: 1.25rem;
}

a {
    text-decoration: none;
    color: inherit;
}

address {
    font-style: normal;
}

img {
    height: 100%;
    max-width: 100%;
    width: 100%;
    display: block;
    object-fit: cover;
}