@use '../abstracts' as *;

#{$mark}logo {
    background-image: url('../../images/logo.svg');
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: rem-calc(460);
    max-width: 100%;
    aspect-ratio: 230 / 37;
    min-height: rem-calc(74);
    margin: 0;
    background-position: left center;

    &__link {
        display: block;
        height: 100%;
        cursor: pointer;
    }

    &__title {
        display: none;
    }
}