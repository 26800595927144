* {
  box-sizing: border-box;
  margin: 0;
}

blockquote, figcaption, figure, ol, p, ul, address {
  margin-bottom: 1.25rem;
}

a {
  text-decoration: none;
  color: inherit;
}

address {
  font-style: normal;
}

img {
  height: 100%;
  max-width: 100%;
  width: 100%;
  display: block;
  object-fit: cover;
}

:root {
  --color-0: #000000;
  --color-50: #FFFFFF;
  --color-100: #FFFDFD;
  --color-150: #F8E1E4;
  --color-200: #0303FC;
  --color-250: #FA5F71;
  --color-300: #E5CACE;
  --color-350: #09003C;
  --color-400: #5a547c;
  --color-450: #DEC0C4;
  --color-500: #020202;
  --color-550: #0403E1;
  --color-600: #FC0329;
  --color-650: #0F0DFB;
  --color-700: #FFE0E4;
  --bgColor: var(--color-350);
  --gradient: linear-gradient(180deg, var(--color-350) 0%, var(--color-550) 60%, var(--color-200) 100%);
  --color: var(--color-100);
  --color-alt: var(--color-150);
  --bgColor-header: var(--color-350);
  --color-header: var(--color-100);
  --color-alt-header: var(--color-150);
  --bgColor-footer: var(--color-350);
  --gradient-footer: linear-gradient(180deg, var(--color-350) 0%, var(--color-550) 60%, var(--color-200) 100%);
  --color-footer: var(--color-100);
  --color-alt-footer: var(--color-300);
  --color-title: var(--color-150);
  --color-headline: var(--color-150);
  --color-active-i18n: var(--color-200);
  --color-hover-i18n: var(--color-250);
  --menu-active-color: var(--color-200);
  --menu-footer-active-color: var(--color-250);
  --menu-footer-color: var(--color-300);
  --borderColor-button: var(--color-250);
  --button-hover-bgColor: var(--color-200);
  --button-hover-color: var(--color-50);
  --button-alt-hover-color: var(--color-200);
  --color-alt-contact: var(--color-300);
  --color-hero: var(--color-150);
  --hero-arrow-color: var(--color-250);
  --hero-arrow-hover-color: var(--color-200);
  --color-active-filters: var(--color-200);
  --cloud-trigger-bgColor: var(--color-100);
  --cloud-trigger-color: var(--color-250);
  --cloud-trigger-border: var(--color-200);
  --color-card: var(--color-150);
  --bgColor-card: var(--color-500);
  --card-hover-color: var(--color-200);
  --card-hover-bgColor: var(--color-150);
  --card-hover-image-filter: var(--color-200);
  --news-color: var(--color-150);
  --news-bgColor: var(--color-500);
  --news-hover-bgColor: var(--color-150);
  --news-hover-color: var(--color-350);
  --facts-color: var(--color-600);
  --canvas-gradient: linear-gradient(180deg, var(--color-200) 0%, var(--color-650) 40%, var(--color-700) 100%);
  --canvas-button-color: var(--color-100);
  --canvas-bgColor: var(--color-200);
  --canvas-color: var(--color-100);
  --canvas-i18n-active-color: var(--color-350);
  --canvas-menu-active-color: var(--color-350);
}

.bb-bg--black {
  background-color: var(--color-0);
}

@keyframes reveal {
  from {
    filter: blur(14px);
    opacity: 0;
  }
  to {
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes revealDelay {
  0% {
    filter: blur(14px);
    opacity: 0;
  }
  40% {
    filter: blur(14px);
    opacity: 0;
  }
  100% {
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes showElement {
  from {
    filter: blur(9px);
  }
  to {
    filter: blur(0);
  }
}
body {
  font-weight: 400;
  letter-spacing: 0.0525rem;
  font-size: 1rem;
  line-height: 1.4;
  word-break: break-word;
  hyphens: auto;
  font-family: "BP-Theinhardt";
}

.bb-card .bb-title,
.bb-news__header .bb-title,
.bb-headline .bb-title,
.bb-teaser__header .bb-title,
.bb-grid__item .bb-title,
.bb-button,
.bb-menu--i18n,
.bb-clouds,
.bb-facts,
.bb-canvas,
.bb-canvas__btn-title {
  font-family: "NB-Architekt";
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

b, strong {
  font-weight: 700;
}

.bb-title {
  color: var(--color-title);
  font-size: 1.5625rem;
  line-height: 1.875rem;
  letter-spacing: 0.2rem;
}
@media (min-width: 64rem) {
  .bb-title {
    font-size: 2.375rem;
    line-height: 2.75rem;
  }
}
.bb-title--highlight {
  color: var(--color-headline);
  font-weight: 700;
  text-transform: lowercase;
  font-size: 6.3125rem;
  line-height: 5.8125rem;
}
@media (min-width: 64rem) {
  .bb-title--highlight {
    font-size: 10rem;
    line-height: 10rem;
  }
}
.bb-title--highlight p {
  margin: 0;
}
.bb-title--line {
  margin-left: -0.625rem;
}

html {
  scroll-behavior: smooth;
  scroll-padding-block-start: 11.875rem;
}
@media (min-width: 64rem) {
  html {
    scroll-padding-block-start: 10.625rem;
  }
}

.bb-main {
  background-color: var(--bgColor);
  padding-top: 11.3125rem;
}
@media (min-width: 64rem) {
  .bb-main {
    padding-top: 9.3125rem;
  }
}

.bb-wrapper {
  background-color: var(--bgColor);
  background: var(--gradient);
  color: var(--color);
  padding-block: 1.1875rem 5.1875rem;
}
@media (min-width: 64rem) {
  .bb-wrapper {
    padding-bottom: 2.5rem;
  }
}
.bb-wrapper > .bb-container > :first-child:not(.bb-button--back) {
  margin-top: 0;
}
.bb-wrapper > .bb-container > :last-child {
  margin-bottom: 0;
}

.bb-container {
  max-width: 89.3125rem;
  margin-inline: auto;
  padding-inline: 1rem;
}
@media (min-width: 40rem) {
  .bb-container {
    padding-inline: 1.75rem;
  }
}
@media (min-width: 64rem) {
  .bb-container {
    padding-inline: 5rem;
  }
}

.bb-content--media {
  margin-block: 5.1875rem 1.8125rem;
}
@media (min-width: 64rem) {
  .bb-content--media {
    margin-block: 2.5rem 3rem;
  }
}
.bb-content--media .bb-media,
.bb-content--media .bb-video {
  margin: 0;
}
.bb-content--media + .bb-latest-cards {
  margin-top: 1.8125rem;
}
@media (min-width: 64rem) {
  .bb-content--media + .bb-latest-cards {
    margin-top: 2.5rem;
  }
}

.bb-hidden {
  display: none;
}

.bb-headline {
  margin-bottom: 1.1875rem;
}
.bb-headline + .bb-content--media {
  margin-block: 1.1875rem;
}

.bb-media--no-image {
  background-color: lightgreen;
  display: inline-block;
  aspect-ratio: 16/10;
  width: 100%;
}

video {
  width: 100%;
  aspect-ratio: 16/10;
  object-fit: cover;
  display: block;
}

.bb-matterport {
  margin-block: 1.1875rem;
}

.bb-header {
  background-color: var(--bgColor-header);
  color: var(--color-header);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}
.bb-header .bb-container {
  padding-block: 1.4375rem 1.75rem;
}
@media (min-width: 64rem) {
  .bb-header .bb-container {
    padding-block: 1rem 1.375rem;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
  }
}
.bb-header__logo {
  display: flex;
}
@media (max-width: 63.94rem) {
  .bb-header__logo {
    margin-bottom: 1.1875rem;
  }
}
.bb-header__logo .bb-logo:hover {
  background-image: url("../../images/logo-light.svg");
}
.bb-header__menu {
  color: var(--color-alt-header);
}
@media (max-width: 63.94rem) {
  .bb-header__menu {
    display: none;
  }
}
@media (min-width: 64rem) {
  .bb-header__menu {
    align-self: center;
  }
}
@media (max-width: 63.94rem) {
  .bb-header__i18n {
    display: none;
  }
}
@media (min-width: 64rem) {
  .bb-header__i18n {
    justify-self: flex-end;
    align-self: flex-end;
    transform: translateY(-18px);
  }
}
.bb-header__actions {
  display: flex;
  gap: 1.125rem;
}
@media (min-width: 64rem) {
  .bb-header__actions {
    transform: translateY(-12px);
  }
}
.bb-header__actions .bb-button {
  overflow: hidden;
  white-space: nowrap;
}

.is-canvas {
  overflow: hidden;
}

.bb-canvas {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  transform: translateX(-130%);
  bottom: 0;
  z-index: 99;
  background-color: var(--canvas-bgColor);
  background: var(--canvas-gradient);
  color: var(--canvas-color);
  z-index: 99;
  padding-block: 1.5rem;
}
.is-open .bb-canvas {
  transform: translateX(0);
}
.bb-canvas__btn {
  border: none;
  padding: 0;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
.bb-canvas__btn-title {
  display: none;
}
.bb-canvas__btn-icon {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}
@media (min-width: 40rem) {
  .bb-canvas__btn-icon {
    margin-right: 1.125rem;
  }
}
.bb-canvas__btn-icon:before, .bb-canvas__btn-icon:after {
  width: 3.75rem;
  height: 0.5625rem;
  background-color: var(--canvas-button-color);
  content: "";
  display: inline-block;
}
@media (min-width: 40rem) {
  .bb-canvas__btn-icon:before, .bb-canvas__btn-icon:after {
    width: 6rem;
  }
}
.bb-canvas__btn--close {
  cursor: pointer;
  background-color: transparent;
  border: none;
  line-height: 5.625rem;
  height: 5rem;
  margin: -1rem 0 0 auto;
  padding: 0;
}
.bb-canvas__btn--close .bb-canvas__btn-title {
  display: block;
  font-size: 6.875rem;
  color: var(--canvas-color);
}
.bb-canvas__inner {
  height: 100%;
  overflow-y: auto;
  padding-inline: 1rem;
}
@media (min-width: 40rem) {
  .bb-canvas__inner {
    padding-inline: 1.75rem;
  }
}
.bb-canvas__nav {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.bb-canvas .bb-header__logo {
  margin: 0;
}
.bb-canvas .bb-logo {
  background-image: url("../../images/logo-dark.svg");
}
.bb-canvas__i18n.bb-menu--i18n {
  margin-bottom: 1.1875rem;
  margin-top: -1.25rem;
  transform: translateY(-100%);
  width: 5.625rem;
}
.bb-canvas__i18n.bb-menu span {
  color: var(--canvas-menu-active-color);
}
.bb-canvas__menu.bb-menu {
  display: flex;
  flex-direction: column;
  font-size: 2.4375rem;
  gap: 1rem;
  margin-top: -4rem;
}
@media (min-width: 40rem) {
  .bb-canvas__menu.bb-menu {
    gap: 1.1875rem;
  }
}
.bb-canvas__menu.bb-menu .bb-menu__link {
  display: block;
}
.bb-canvas__menu.bb-menu .bb-menu__link.bb-active {
  color: var(--canvas-menu-active-color);
}

.bb-footer {
  background-color: var(--bgColor-footer);
  background: var(--gradient-footer);
  color: var(--color-footer);
  padding-block: 1.1875rem;
  position: relative;
}
@media (min-width: 64rem) {
  .bb-footer {
    padding-block: 0rem 0.75rem;
  }
}
@media (min-width: 64rem) {
  .bb-footer .bb-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.bb-footer .bb-logo {
  margin-bottom: 2.6875rem;
}
@media (min-width: 64rem) {
  .bb-footer .bb-logo {
    margin-top: 3.25rem;
    width: 15.375rem;
  }
}
.bb-footer__contact {
  font-weight: 700;
  font-size: 1.125rem;
  color: var(--color-footer-contact);
}
@media (min-width: 64rem) {
  .bb-footer__contact {
    width: 18.75rem;
  }
}
.bb-footer__text {
  font-weight: 700;
  text-transform: lowercase;
  font-size: 6.3125rem;
  line-height: 5.8125rem;
  margin-bottom: 1.375rem;
}
@media (min-width: 64rem) {
  .bb-footer__text {
    font-size: 10rem;
    line-height: 10rem;
  }
}
@media (min-width: 64rem) {
  .bb-footer__text {
    font-size: 5rem;
    line-height: 5rem;
    width: 62%;
    margin-top: -0.625rem;
  }
}
.bb-footer__text p {
  margin: 0;
}
@media (min-width: 64rem) {
  .bb-footer__service {
    width: 100%;
  }
}
@media (min-width: 64rem) {
  .bb-footer__service .bb-menu--service {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media (min-width: 64rem) {
  .bb-footer__service .bb-menu--service li:first-child {
    width: 18.75rem;
  }
}
@media (min-width: 64rem) {
  .bb-footer__service .bb-menu--service li:nth-child(2) {
    width: 62%;
  }
}
@media (min-width: 64rem) {
  .bb-footer__service .bb-menu--service li:nth-child(2) .bb-menu__link {
    padding-left: 0.375rem;
  }
}

.bb-tiles {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  row-gap: 1.8125rem;
}
@media (min-width: 64rem) {
  .bb-tiles {
    column-gap: 1.1875rem;
    row-gap: 3rem;
  }
}
@media (min-width: 64rem) {
  .bb-tiles--offset {
    row-gap: 0;
  }
}
@media (min-width: 64rem) {
  .bb-tiles--offset:not(.bb-tiles--offset.bb-reverse) .bb-tiles__item:nth-of-type(even) .bb-tiles__item--offset {
    padding-top: 31%;
  }
}
@media (min-width: 64rem) {
  .bb-tiles--offset.bb-reverse .bb-tiles__item:nth-of-type(odd) .bb-tiles__item--offset {
    padding-top: 31%;
  }
}
@media (min-width: 64rem) {
  .bb-tiles--offset.bb-reverse .bb-tiles__item:not(.bb-tiles--offset.bb-reverse .bb-tiles__item--action):first-child {
    order: 1;
  }
}
@media (min-width: 64rem) {
  .bb-tiles--offset.bb-reverse .bb-tiles__item:not(.bb-tiles--offset.bb-reverse .bb-tiles__item--action):nth-child(2) {
    order: 2;
  }
}
@media (min-width: 64rem) {
  .bb-tiles--offset.bb-reverse .bb-tiles__item:not(.bb-tiles--offset.bb-reverse .bb-tiles__item--action):nth-child(3) {
    order: 4;
  }
}
@media (min-width: 64rem) {
  .bb-tiles--offset.bb-reverse .bb-tiles__item:not(.bb-tiles--offset.bb-reverse .bb-tiles__item--action):nth-child(3) .bb-tiles__item--offset {
    padding-top: 0;
  }
}
@media (min-width: 64rem) {
  .bb-tiles--offset.bb-reverse .bb-tiles__item--action {
    order: 3;
  }
}
@media (min-width: 64rem) {
  .bb-tiles--offset.bb-reverse .bb-tiles__item--action .bb-tiles__item--offset {
    padding-top: 31%;
  }
}
.bb-tiles--offset.bb-reverse .bb-tiles__item--action:nth-child(even) {
  text-align: left;
}
@media (min-width: 64rem) {
  .bb-tiles--offset.bb-reverse .bb-tiles__item--action:nth-child(2) .bb-tiles__item--offset {
    padding-top: 0;
    text-align: right;
  }
}
.bb-tiles__item {
  width: 100%;
}
@media (min-width: 64rem) {
  .bb-tiles__item {
    width: calc(50% - 18.5px);
  }
}
.bb-tiles__item .bb-button--group {
  margin-block: 0.8125rem 0;
}
.bb-tiles__item--action:nth-child(even) {
  text-align: right;
}
.bb-tiles + .bb-headline {
  margin-top: 4.0625rem;
}
@media (min-width: 64rem) {
  .bb-tiles + .bb-headline {
    margin-top: 8.125rem;
  }
}

.bb-grid {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  row-gap: 1.8125rem;
}
@media (min-width: 64rem) {
  .bb-grid {
    column-gap: 1.1875rem;
  }
}
@media (min-width: 64rem) {
  .bb-grid.bb-reverse {
    flex-direction: row-reverse;
  }
}
.bb-grid__item {
  width: 100%;
}
@media (min-width: 64rem) {
  .bb-grid__item {
    width: calc(50% - 18.5px);
  }
}
.bb-grid__item--action {
  display: flex;
  align-items: flex-end;
}
.bb-right .bb-grid__item--action {
  justify-content: flex-end;
}
.bb-right .bb-grid__item--action .bb-button--group {
  align-items: flex-end;
}
.bb-grid__item > *:last-child {
  margin-bottom: 0;
}

.bb-list {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}
.bb-list__item {
  width: 100%;
}
.bb-list__item:not(:last-of-type) {
  margin-bottom: 1.8125rem;
}
@media (min-width: 64rem) {
  .bb-list__item:not(:last-of-type) {
    margin-bottom: 3rem;
  }
}

.is-blured {
  filter: blur(14px);
}

.is-revealed {
  animation-duration: 1500ms;
  animation-timing-function: ease-in-out;
  animation-name: showElement;
}

[data-reveal] > *:not(.animation-in) {
  opacity: 0;
}
[data-reveal] span {
  opacity: 0;
  filter: blur(14px);
  transition-property: opacity filter;
  transition-duration: 1000ms;
  transition-timing-function: ease-in-out;
}
[data-reveal] span.animation-in {
  opacity: 1;
  filter: blur(0);
}

.bb-rte {
  font-size: 1.25rem;
  line-height: 1.625rem;
}
@media (min-width: 64rem) {
  .bb-rte {
    font-size: 1.75rem;
    line-height: 2.125rem;
  }
}
.bb-rte :last-child {
  margin-bottom: 0;
}
.bb-rte + .bb-card {
  margin-top: 1.1875rem;
}

.bb-logo {
  background-image: url("../../images/logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 28.75rem;
  max-width: 100%;
  aspect-ratio: 230/37;
  min-height: 4.625rem;
  margin: 0;
  background-position: left center;
}
.bb-logo__link {
  display: block;
  height: 100%;
  cursor: pointer;
}
.bb-logo__title {
  display: none;
}

.bb-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}
.bb-menu__link {
  line-height: 1;
}
.bb-menu--i18n {
  margin: -0.75rem;
  text-transform: uppercase;
}
.bb-menu--i18n .bb-menu__link {
  padding: 0.75rem;
  display: block;
}
.bb-menu--i18n span {
  color: var(--color-active-i18n);
}
.bb-menu--i18n a:hover {
  color: var(--color-hover-i18n);
}
.bb-menu--main {
  margin: -0.75rem -0.9375rem;
  font-size: 1.125rem;
  font-weight: 700;
  text-transform: lowercase;
}
.bb-menu--main .bb-menu__link {
  padding: 0.75rem 0.9375rem;
  display: block;
}
@media (min-width: 64rem) {
  .bb-menu--main .bb-menu__link {
    white-space: nowrap;
  }
}
.bb-menu--main .bb-menu__link:hover, .bb-menu--main .bb-menu__link.bb-active {
  color: var(--menu-active-color);
}
.bb-menu--service {
  margin: -0.75rem -0.9375rem;
  font-size: 1.125rem;
  font-weight: 500;
  text-transform: lowercase;
  color: var(--menu-footer-color);
}
@media (max-width: 63.94rem) {
  .bb-menu--service {
    flex-direction: column;
    row-gap: 1rem;
  }
}
.bb-menu--service .bb-menu__link {
  line-height: 1.4375rem;
  padding: 0.75rem 0.9375rem;
  display: block;
}
@media (min-width: 64rem) {
  .bb-menu--service .bb-menu__link {
    display: inline-block;
  }
}
.bb-menu--service .bb-menu__link:hover, .bb-menu--service .bb-menu__link.bb-active {
  color: var(--menu-footer-active-color);
}

.bb-button {
  display: block;
  border: 0.3125rem solid var(--borderColor-button);
  padding: 0.5rem 0.5rem 0.375rem;
  text-align: center;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: 0.13rem;
}
@media (min-width: 64rem) {
  .bb-button {
    display: inline-block;
  }
}
.bb-button:not(.bb-button.bb-button--alt):hover {
  border-color: transparent;
  background-color: var(--button-hover-bgColor);
  color: var(--button-hover-color);
}
.bb-button--alt {
  padding: 0.8125rem 0rem 0.6875rem;
  border: none;
  text-decoration: underline;
}
.bb-button--alt:hover {
  text-decoration: none;
  color: var(--button-alt-hover-color);
}
.bb-button--group {
  display: flex;
  flex-direction: column;
  gap: 1.1875rem;
  margin-block: 1.8125rem 1.1875rem;
}
@media (min-width: 64rem) {
  .bb-button--group {
    margin-top: 1.1875rem;
  }
}
.bb-button--group-alt {
  flex-direction: row;
}
@media (min-width: 64rem) {
  .bb-button--group .bb-button {
    width: fit-content;
  }
}
.bb-button--back {
  margin-block: -0.8125rem 1.625rem;
}
@media (max-width: 63.94rem) {
  .bb-button--back .bb-button {
    text-align: left;
  }
}

.bb-get-in-touch__title {
  line-height: 2.625rem;
  font-size: 2.5rem;
  color: var(--color-alt-contact);
}
@media (max-width: 63.94rem) {
  .bb-get-in-touch__title {
    display: none;
  }
}
.bb-get-in-touch__address {
  line-height: 1.6;
  margin-bottom: 3rem;
}
.bb-get-in-touch__address p {
  margin: 0;
}
@media (min-width: 64rem) {
  .bb-get-in-touch__address p:nth-of-type(2) {
    margin-top: 1.375rem;
  }
}
.bb-get-in-touch__address .bb-button {
  margin-top: 0.125rem;
}
@media (max-width: 63.94rem) {
  .bb-get-in-touch__address .bb-button {
    display: inline-block;
  }
}

.bb-hero .bb-title--highlight,
.bb-hero--alt .bb-title--highlight {
  color: var(--color-hero);
  margin-top: -0.5625rem;
  margin-bottom: 1.1875rem;
}
@media (min-width: 64rem) {
  .bb-hero .bb-title--highlight,
  .bb-hero--alt .bb-title--highlight {
    margin-top: -1.1875rem;
    font-size: 13.75rem;
    line-height: 12.6875rem;
  }
}

.bb-hero:not(.bb-hero.bb-hero--special) {
  position: relative;
}
@media (max-width: 39.94rem) {
  .bb-hero:not(.bb-hero.bb-hero--special) {
    aspect-ratio: 16/9;
  }
}
@media (min-width: 40rem) {
  .bb-hero:not(.bb-hero.bb-hero--special) {
    min-height: calc(100dvh - 172px);
  }
}
@media (min-width: 64rem) {
  .bb-hero:not(.bb-hero.bb-hero--special) {
    min-height: calc(100dvh - 130px);
  }
}
.bb-hero:not(.bb-hero.bb-hero--special) .bb-container {
  position: relative;
  z-index: 1;
}
.bb-hero__media {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
}
.bb-hero__media .bb-media {
  height: 100%;
  width: 100%;
  margin: 0;
}
.bb-hero__media .bb-media__image {
  object-fit: cover;
}
.bb-hero__media .bb-consentbanner-placeholder {
  height: 100%;
}
.bb-hero__media video {
  height: 100%;
}
.bb-hero__media iframe {
  width: 100%;
  border: none;
  height: 100%;
}
.bb-hero--special {
  color: var(--color-hero);
  padding-bottom: 1.1875rem;
  margin-bottom: 4.0625rem;
}
@media (min-width: 64rem) {
  .bb-hero--special {
    margin-bottom: 2.5rem;
  }
}
.bb-hero--special .bb-container:first-child {
  min-height: 100dvh;
  position: relative;
  z-index: 1;
}
.bb-hero--special .bb-title--highlight {
  color: var(--color-hero);
}
.bb-hero--special .bb-title--highlight P {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.bb-hero--special .bb-title--highlight P .bb-width-big {
  width: 100%;
}
.bb-hero--special .bb-title--highlight P .bb-width-small {
  width: auto;
}
.bb-hero--special .bb-textarea-btn {
  margin-block: 1.1875rem 0;
}

.bb-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  color: var(--color-card);
}
.bb-card__link {
  display: inline-block;
}
.bb-card__link:hover .bb-media:before {
  opacity: 0.8;
}
.bb-card__link:hover .bb-card__details:before {
  height: 6.875rem;
  background-color: var(--card-hover-bgColor);
}
.bb-card__link:hover .bb-title {
  color: var(--card-hover-color);
}
.bb-card__link:hover .bb-card__description {
  color: transparent;
}
.bb-card__link .bb-media {
  position: relative;
}
.bb-card__link .bb-media:before {
  background-color: var(--card-hover-image-filter);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  display: inline-block;
  position: absolute;
  opacity: 0;
  transition: ease-in-out 200ms opacity;
}
.bb-card__link .bb-card__details {
  background-color: transparent;
  position: relative;
}
.bb-card__link .bb-card__details:before {
  content: "";
  display: inline-block;
  position: absolute;
  transition-property: background-color height;
  transition-duration: 200ms;
  transition-timing-function: ease-in;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  background-color: var(--bgColor-card);
  z-index: 1;
}
.bb-card__link .bb-title,
.bb-card__link .bb-card__description {
  position: relative;
  z-index: 2;
  transition-property: color;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
}
.bb-card__details {
  padding: 0.8125rem 0rem 0.625rem;
  background-color: var(--bgColor-card);
}
.bb-card__details .bb-title {
  color: var(--color-card);
  text-decoration: underline;
  line-height: 1.5625rem;
  height: 3.125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 400;
  letter-spacing: 0.203125rem;
}
@media (min-width: 64rem) {
  .bb-card__details .bb-title {
    line-height: 2.8125rem;
  }
}
@media (min-width: 64rem) {
  .bb-card__details .bb-title {
    letter-spacing: 0.316875rem;
  }
}
@media (min-width: 64rem) {
  .bb-card__details .bb-title {
    height: 5.625rem;
  }
}
.bb-card__description {
  margin: 0;
  min-height: 2.375rem;
  transition: ease-in-out 200ms color;
  line-height: 1.1875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media (min-width: 64rem) {
  .bb-card__description {
    font-size: 1.75rem;
    line-height: 2.125rem;
  }
}
@media (min-width: 64rem) {
  .bb-card__description {
    height: 4.25rem;
  }
}
.bb-card .bb-media {
  margin: 0;
  order: -1;
}
.bb-card .bb-media .bb-video {
  height: 100%;
}

.bb-news__list {
  margin-bottom: 2.5rem;
}
@media (min-width: 64rem) {
  .bb-news__list .bb-media {
    height: 100%;
    max-height: 11.1875rem;
  }
}
@media (min-width: 64rem) {
  .bb-news__grid {
    grid-template-columns: 1fr 1fr;
    display: grid;
    gap: 1.1875rem;
  }
}
.bb-news__grid .bb-media {
  margin: 0;
}
.bb-news__details {
  background-color: var(--news-bgColor);
  color: var(--news-color);
  font-size: 1.25rem;
  line-height: 1.625rem;
  transition-property: background-color color;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
}
@media (min-width: 64rem) {
  .bb-news__details {
    font-size: 1.75rem;
    line-height: 2.125rem;
  }
}
@media (min-width: 64rem) {
  .bb-news__details {
    padding: 0rem 0rem 0.625rem;
  }
}
.bb-news__header {
  display: flex;
  flex-direction: column-reverse;
}
.bb-news__header .bb-title {
  text-decoration: underline;
  color: var(--news-color);
  line-height: 1.5625rem;
  font-weight: 400;
  transition: color ease-in-out 200ms;
  letter-spacing: 0.203125rem;
}
@media (min-width: 64rem) {
  .bb-news__header .bb-title {
    letter-spacing: 0.316875rem;
  }
}
@media (min-width: 64rem) {
  .bb-news__header .bb-title:not(.bb-news--preview .bb-news__header .bb-title) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 8.4375rem;
    line-height: 2.8125rem;
  }
}
.bb-news__eyebrow {
  font-weight: 700;
}
.bb-news--teaser .bb-news .bb-media {
  width: 100%;
  height: 100%;
}
.bb-news--teaser .bb-news .bb-media__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bb-news-latest {
  margin-top: 4.0625rem;
}
@media (min-width: 64rem) {
  .bb-news-latest {
    margin-top: 8.125rem;
    position: relative;
  }
}
.bb-news-latest .bb-news__link {
  display: inline-block;
  margin-top: 1.8125rem;
}
@media (min-width: 64rem) {
  .bb-news-latest .bb-news__link {
    margin-top: 3rem;
  }
}
.bb-news-latest .bb-news__details {
  background: transparent;
  position: relative;
}
.bb-news-latest .bb-news__details:hover {
  color: var(--news-hover-color);
}
.bb-news-latest .bb-news__details:hover:before {
  background-color: var(--news-hover-bgColor);
}
.bb-news-latest .bb-news__details:hover .bb-title {
  color: var(--news-hover-color);
}
.bb-news-latest .bb-news__details:before {
  background-color: var(--news-bgColor);
  transition: background-color ease-in-out 200ms;
  content: "";
  display: inline-block;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  z-index: 1;
}
.bb-news-latest .bb-news__details > * {
  z-index: 2;
  position: relative;
}
.bb-news-latest .bb-media {
  margin-bottom: 1.8125rem;
}
@media (min-width: 64rem) {
  .bb-news-latest .bb-media {
    margin-bottom: 3rem;
  }
}
@media (min-width: 64rem) {
  .bb-news-latest .bb-button {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
.bb-news-latest + .bb-content--media {
  margin-top: 4.0625rem;
}
@media (min-width: 64rem) {
  .bb-news-latest + .bb-content--media {
    margin-top: 8.125rem;
  }
}
.bb-news__item .bb-news__link:hover .bb-news__details {
  background-color: var(--news-hover-bgColor);
  color: var(--news-hover-color);
}
.bb-news__item .bb-news__link:hover .bb-title {
  color: var(--news-hover-color);
}
.bb-news--preview {
  align-items: center;
}
.bb-news--preview .bb-title {
  line-height: 1.5625rem;
}
@media (min-width: 64rem) {
  .bb-news--preview .bb-title {
    line-height: 2.8125rem;
  }
}

.bb-clouds {
  margin-bottom: 1.1875rem;
}
@media (max-width: 63.94rem) {
  .bb-clouds {
    position: relative;
  }
}
@media (min-width: 64rem) {
  .bb-clouds {
    margin-bottom: 1.6875rem;
  }
}
.bb-clouds.is-sticky {
  right: 1rem;
  position: fixed;
  top: 11.3125rem;
  left: 1rem;
  z-index: 66;
}
@media (min-width: 40rem) {
  .bb-clouds.is-sticky {
    left: 1.75rem;
    right: 1.75rem;
  }
}
.bb-clouds.is-sticky + .is-offset {
  margin-top: 4.8125rem;
}
.bb-clouds__cb {
  position: absolute;
  z-index: -99;
}
@media (min-width: 64rem) {
  .bb-clouds__cb {
    display: none;
  }
}
@media (max-width: 63.94rem) {
  .bb-clouds__cb:checked + .bb-clouds__trigger:before {
    border-top: none;
    border-bottom: 1.0625rem solid var(--cloud-trigger-color);
  }
}
@media (max-width: 63.94rem) {
  .bb-clouds__cb:checked ~ .bb-clouds__list {
    display: block;
    position: absolute;
    top: 2.5rem;
    left: 0;
    right: 0;
    z-index: 66;
  }
}
.bb-clouds__trigger {
  background-color: var(--cloud-trigger-bgColor);
  display: block;
  color: var(--cloud-trigger-color);
  border: 0.3125rem solid var(--cloud-trigger-border);
  text-transform: uppercase;
  padding: 0.40625rem 2.5rem 0.28125rem 0.5625rem;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: 0.13rem;
}
@media (min-width: 64rem) {
  .bb-clouds__trigger {
    display: none;
  }
}
.bb-clouds__trigger:before {
  content: "";
  display: inline-block;
  border-top: 1.0625rem solid var(--cloud-trigger-color);
  border-left: 0.625rem solid transparent;
  border-right: 0.625rem solid transparent;
  position: absolute;
  right: 0.5625rem;
  top: 0.375rem;
}
.bb-clouds__list {
  padding: 0;
  margin: 0;
  list-style: none;
}
@media (max-width: 63.94rem) {
  .bb-clouds__list {
    height: 12.5rem;
    overflow-y: auto;
    background-color: var(--cloud-trigger-bgColor);
    display: none;
  }
}
@media (min-width: 64rem) {
  .bb-clouds__list {
    display: flex;
    column-gap: 1.5rem;
    flex-wrap: wrap;
  }
}
.bb-clouds__item.is-active .bb-button {
  color: var(--color-active-filters);
  text-decoration: none;
}
@media (max-width: 63.94rem) {
  .bb-clouds__item .bb-button {
    text-align: left;
    padding-inline: 0.75rem;
  }
}
.bb-clouds + .bb-headline {
  margin-bottom: 1.8125rem;
}
@media (min-width: 64rem) {
  .bb-clouds + .bb-headline {
    margin-bottom: 3rem;
  }
}

.bb-latest-cards {
  margin-top: 4.0625rem;
}
@media (min-width: 64rem) {
  .bb-latest-cards {
    margin-top: 8.125rem;
  }
}
.bb-latest-cards .bb-tiles {
  margin-top: 1.8125rem;
}
@media (min-width: 64rem) {
  .bb-latest-cards .bb-tiles {
    margin-top: 3rem;
  }
}

.bb-mosaic {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "big";
  gap: 1.5rem;
}
.bb-mosaic:not(:last-child) {
  margin-bottom: 1.5rem;
}
.bb-mosaic--2, .bb-mosaic--3, .bb-mosaic--4 {
  grid-template-columns: 2fr 1fr;
}
.bb-mosaic--2.bb-mosaic--reverse, .bb-mosaic--3.bb-mosaic--reverse, .bb-mosaic--4.bb-mosaic--reverse {
  grid-template-columns: 1fr 2fr;
}
.bb-mosaic--2 {
  grid-template-areas: "big top";
}
.bb-mosaic--2.bb-mosaic--reverse {
  grid-template-areas: "top big";
}
.bb-mosaic--3 {
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "big top" "big middle";
}
.bb-mosaic--3.bb-mosaic--reverse {
  grid-template-areas: "top big" "middle big";
}
.bb-mosaic--4 {
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: "big top" "big middle" "big bottom";
}
.bb-mosaic--4.bb-mosaic--reverse {
  grid-template-areas: "top big" "middle big" "bottom big";
}
.bb-mosaic .bb-media {
  margin: 0;
}
.bb-mosaic .bb-media--big {
  grid-area: big;
}
.bb-mosaic .bb-media--top {
  grid-area: top;
}
.bb-mosaic .bb-media--middle {
  grid-area: middle;
}
.bb-mosaic .bb-media--bottom {
  grid-area: bottom;
}

.bb-textarea-cols {
  margin-block: 1.1875rem;
}
@media (min-width: 64rem) {
  .bb-textarea-cols__grid {
    display: flex;
    margin: -0.625rem;
  }
}
@media (min-width: 64rem) {
  .bb-textarea-cols .bb-col {
    width: 50%;
    padding: 0.625rem;
  }
}
@media (max-width: 63.94rem) {
  .bb-textarea-cols .bb-col:not(.bb-textarea-cols .bb-col:last-child) {
    margin-bottom: 3rem;
  }
}
.bb-textarea-cols .bb-col .bb-media {
  margin-top: 1.1875rem;
}
.bb-textarea-btn {
  margin-block: 2.5rem 4.0625rem;
}
@media (max-width: 63.94rem) {
  .bb-textarea-btn .bb-grid__item--action {
    display: block;
  }
}

.bb-facts {
  display: flex;
  flex-wrap: wrap;
  margin-block: 1.1875rem;
  column-gap: 0.625rem;
  letter-spacing: 0.13rem;
}
.bb-facts__item {
  margin: 0;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 0.625rem;
}
.bb-facts__item span {
  color: var(--facts-color);
  display: inline-block;
}
.bb-facts__break {
  width: 100%;
  height: 0;
}

@media (min-width: 64rem) {
  .bb-teaser {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: auto 17.9375rem;
  }
}
.bb-teaser__header {
  margin-bottom: 0.8125rem;
}
.bb-teaser__intro {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.625rem;
}
@media (min-width: 64rem) {
  .bb-teaser__intro {
    font-size: 1.75rem;
    line-height: 2.125rem;
  }
}
.bb-teaser__actions {
  margin-top: 0.8125rem;
  display: flex;
  gap: 1.6875rem;
}
@media (max-width: 63.94rem) {
  .bb-teaser__actions .bb-button {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 63.94rem) {
  .bb-teaser__actions .bb-button--alt {
    display: none;
  }
}
.bb-teaser__media {
  overflow: hidden;
  border-radius: 50%;
  width: 17.9375rem;
  aspect-ratio: 1;
  min-width: 17.9375rem;
}
@media (max-width: 63.94rem) {
  .bb-teaser__media {
    display: none;
  }
}
.bb-teaser__media .bb-video,
.bb-teaser__media .bb-media__image {
  object-fit: cover;
}
.bb-teaser__media .bb-video,
.bb-teaser__media .bb-media {
  height: 100%;
  margin: 0;
}

@media (min-width: 64rem) {
  .bb-job .bb-title--highlight {
    font-size: 10.625rem;
    line-height: 10.625rem;
  }
}
@media (min-width: 64rem) {
  .bb-job .bb-title--highlight:before {
    top: 3.5625rem;
  }
}
.bb-job .bb-button {
  margin-top: 0.3125rem;
}

.bb-theme--main {
  --bgColor: var(--color-50);
  --gradient: var(--color-50);
  --color: var(--color-350);
  --bgColor-header: var(--color-200);
  --menu-active-color: var(--color-350);
  --color-active-i18n: var(--color-350);
  --color-title: var(--color-200);
  --color-headline: var(--color-350);
  --color-news: var(--color-150);
  --bgColor-news: var(--color-500);
}

.bb-theme--main-dark {
  --bgColor: var(--color-50);
  --gradient: var(--color-50);
  --color: var(--color-350);
  --color-title: var(--color-200);
  --color-headline: var(--color-350);
  --color-news: var(--color-150);
  --bgColor-news: var(--color-500);
}

.bb-home .bb-latest-cards ~ .bb-latest-cards {
  --color-card: var(--color-350);
  --bgColor-card: var(--color-150);
}
@media (max-width: 63.94rem) {
  .bb-home .bb-wrapper .bb-button,
  .bb-home .bb-hero .bb-button {
    display: block;
    text-align: center;
  }
}

.bb-article {
  --color-hero: var(--color-350);
  --color-card: var(--color-150);
  --bgColor-card: var(--color-350);
}

.bb-projects {
  --color-card: var(--color-350);
  --bgColor-card: var(--color-150);
}
.bb-projects .bb-headline {
  margin-bottom: 1.8125rem;
}
@media (min-width: 64rem) {
  .bb-projects .bb-headline {
    margin-bottom: 3rem;
  }
}