@use '../abstracts' as *;

#{$mark}tiles {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    row-gap: $margin-small-mob;

    @include mq-up(l) {
        column-gap: $margin-fixed;
        row-gap: $margin-small-desk;
    }


    &--offset {
        
        @include mq-up(l) {
            row-gap: 0;
        }
        
        &:not(&#{$mark}reverse) {

            #{$mark}tiles__item {

                &:nth-of-type(even) {

                    #{$mark}tiles__item--offset {

                        @include mq-up(l) {
                            padding-top: 31%;       
                        }
                    }
                }
            }
        }

        &#{$mark}reverse {

            #{$mark}tiles__item {

                &:nth-of-type(odd) {

                    #{$mark}tiles__item--offset {

                        @include mq-up(l) {
                            padding-top: 31%;       
                        }
                    }
                }

                &:not(&--action):first-child {
                    
                    @include mq-up(l) {
                        order: 1;
                    }
                }

                &:not(&--action):nth-child(2) {
                    @include mq-up(l) {
                        order: 2;
                    }
                }

                &:not(&--action):nth-child(3) {
                    @include mq-up(l) {
                        order: 4;
                    }

                    #{$mark}tiles__item--offset {

                        @include mq-up(l) {
                            padding-top: 0;       
                        }
                    }
                }

                &--action {

                    @include mq-up(l) {
                        order: 3;
                    }

                    #{$mark}tiles__item--offset {

                        @include mq-up(l) {
                            padding-top: 31%;       
                        }
                    }
                    
                    &:nth-child(even) {
                        text-align: left;
                    }

                    &:nth-child(2) {

                        #{$mark}tiles__item--offset {

                            @include mq-up(l) {
                                padding-top: 0;     
                                text-align: right;  
                            }
                        }
                    }
                }
            }
        }
    }

    &__item {
        width: 100%;

        @include mq-up(l) {
            width: calc(50% - 18.5px);
        }

        #{$mark}button--group {
            margin-block: $margin-small 0;
        }

        &--action {

            &:nth-child(even) {
                text-align: right;
            }
        }
    }

    + #{$mark}headline {
        margin-top: $margin-large-mob;
    
        @include mq-up(l) {
            margin-top: $margin-large-desk;
        }
    }
}