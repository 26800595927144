:root {
    --color-0: #000000;
    --color-50: #FFFFFF;
    --color-100: #FFFDFD;
    --color-150: #F8E1E4;
    --color-200: #0303FC;
    --color-250: #FA5F71;
    --color-300: #E5CACE;
    --color-350: #09003C; 
    --color-400: #5a547c;
    --color-450: #DEC0C4;
    --color-500: #020202;
    --color-550: #0403E1;
    --color-600: #FC0329;
    --color-650: #0F0DFB;
    --color-700: #FFE0E4;

    // MAIN
    --bgColor: var(--color-350);
    --gradient: linear-gradient(180deg, var(--color-350) 0%, var(--color-550) 60%, var(--color-200) 100%);  
    --color: var(--color-100);
    --color-alt: var(--color-150);

    // HEADER
    --bgColor-header: var(--color-350);
    --color-header: var(--color-100);
    --color-alt-header: var(--color-150);

    // FOOTER
    --bgColor-footer: var(--color-350);
    --gradient-footer: linear-gradient(180deg, var(--color-350) 0%, var(--color-550) 60%, var(--color-200) 100%);   
    --color-footer: var(--color-100);
    --color-alt-footer: var(--color-300);

    // TITLE
    --color-title: var(--color-150);
    --color-headline: var(--color-150);

    //I18N
    --color-active-i18n: var(--color-200); 
    --color-hover-i18n: var(--color-250); 

    // MENU
    --menu-active-color: var(--color-200);
    --menu-footer-active-color: var(--color-250);
    --menu-footer-color: var(--color-300);

    // BUTTON
    --borderColor-button: var(--color-250); 
    --button-hover-bgColor: var(--color-200);
    --button-hover-color: var(--color-50);
    --button-alt-hover-color: var(--color-200);

    // CONTACT
    --color-alt-contact: var(--color-300);

    // HERO
    --color-hero: var(--color-150);
    --hero-arrow-color: var(--color-250);
    --hero-arrow-hover-color: var(--color-200);


    // FILTER
    --color-active-filters: var(--color-200);
    --cloud-trigger-bgColor: var(--color-100);
    --cloud-trigger-color: var(--color-250);
    --cloud-trigger-border: var(--color-200);

    // CARD
    --color-card: var(--color-150);
    --bgColor-card: var(--color-500);
    --card-hover-color: var(--color-200);
    --card-hover-bgColor: var(--color-150);
    --card-hover-image-filter: var(--color-200);

    // NEWS
    --news-color: var(--color-150);
    --news-bgColor: var(--color-500);
    --news-hover-bgColor: var(--color-150);
    --news-hover-color: var(--color-350);

    // PROJECT
    --facts-color: var(--color-600);

    // CANVAS
    --canvas-gradient: linear-gradient(180deg, var(--color-200) 0%, var(--color-650) 40%, var(--color-700) 100%); 
    --canvas-button-color: var(--color-100);
    --canvas-bgColor: var(--color-200);
    --canvas-color: var(--color-100); 

    --canvas-i18n-active-color: var(--color-350);
    --canvas-menu-active-color: var(--color-350);
};