@use '../abstracts' as *;

#{$mark}rte {
    @include rteTypo;

    :last-child {
        margin-bottom: 0;
    }

    + #{$mark}card {
        margin-top: $margin-fixed;
    }
}