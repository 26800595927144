@use '../abstracts' as *;

#{$mark}home {
    
    #{$mark}latest-cards {

        & ~ #{$mark}latest-cards {

            // CARD
            --color-card: var(--color-350);
            --bgColor-card: var(--color-150);
        }
    }

    #{$mark}wrapper,
    #{$mark}hero {

        #{$mark}button {
            
            @include mq-to(l) {
                display: block;
                text-align: center;
            }
        }
    }
}

#{$mark}article {
    // HERO
    --color-hero: var(--color-350);
    
    // CARD
    --color-card: var(--color-150);
    --bgColor-card: var(--color-350);
}
  
#{$mark}projects {
    // CARD
    --color-card: var(--color-350);
    --bgColor-card: var(--color-150);


    #{$mark}headline {
        margin-bottom: $margin-small-mob;
    
        @include mq-up(l) {
            margin-bottom: $margin-small-desk;
        }
    }
}