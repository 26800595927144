@use '../abstracts' as *;

#{$mark}get-in-touch {

   &__title {
      @include footerHeadlineType;
      color: var(--color-alt-contact);

      @include mq-to(l) {
         display: none;
      }
   }

   &__address {
      line-height: 1.6;
      margin-bottom: rem-calc(48);

      p {
         margin: 0;

         &:nth-of-type(2) {
            
            @include mq-up(l) {
               margin-top: rem-calc(22);
            }
         }
      }

      #{$mark}button {
         margin-top: rem-calc(2);

         @include mq-to(l) {
            display: inline-block;
         }
      }
   }
}