@use '../abstracts' as *;

#{$mark}card {
    height: 100%;
    display: flex;
    flex-direction: column;
    color: var(--color-card);

    &__link {
        display: inline-block;

        &:hover {
            
            #{$mark}media {

                &:before {
                    opacity: .8;
                }
            }

            #{$mark}card__details {

                &:before {
                    height: rem-calc(110);
                    background-color: var(--card-hover-bgColor);
                }
            }

            #{$mark}title {
                color: var(--card-hover-color);
            }
            
            #{$mark}card__description {
                color: transparent;
            }
        }

        #{$mark}media {
            position: relative;
    
            &:before {
                background-color: var(--card-hover-image-filter);
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                content: '';
                display: inline-block;
                position: absolute;
                opacity: 0;
                transition: ease-in-out 200ms opacity;
            }
        }

        #{$mark}card__details {
            background-color: transparent;
            position: relative;

            &:before {
                content: '';
                display: inline-block;
                position: absolute;
                transition-property: background-color height;
                transition-duration: 200ms;
                transition-timing-function: ease-in;
                top: 0;
                right: 0;
                left: 0;
                height: 100%;
                background-color: var(--bgColor-card);
                z-index: 1;
            }
        }

        #{$mark}title,
        #{$mark}card__description {
            position: relative;
            z-index: 2;
            transition-property: color;
            transition-duration: 200ms;
            transition-timing-function: ease-in-out;
        }
    }

    &__details {
        padding: rem-calc(13 0 10);
        background-color: var(--bgColor-card);

        #{$mark}title {
            color: var(--color-card);
            text-decoration: underline;
            @include  headlineHeightTypo;
            height: rem-calc(50);
            @include cropText(2);
            font-weight: 400;
            @include headlineLetterSpacingTypo;
            
            @include mq-up(l) {
                height: rem-calc(90);
            }
        }
    }
    
    &__description {
        margin: 0;
        min-height: rem-calc(38);
        transition: ease-in-out 200ms color;
        @include defaultBig;
        @include cropText(2);
        
        @include mq-up(l) {
            height: rem-calc(68);
        }
    }

    #{$mark}media {
        margin: 0;
        order: -1;

        #{$mark}video {
            height: 100%;
        }
    }
}