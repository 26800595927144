@use '../abstracts' as *;

#{$mark}job {

    #{$mark}title--highlight {
        @include headlineBigAltTypo;
       

        &:before {

            @include mq-up(l) {
                top: rem-calc(57);
            }
        }
    }

    #{$mark}button {
        margin-top: rem-calc(5);
    }
}