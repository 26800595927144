@use '../abstracts' as *;

#{$mark}clouds {
    margin-bottom: $margin-fixed;
 
    @include mq-to(l) {
        position: relative;
    }

    @include mq-up(l) {
        margin-bottom: rem-calc(27);
    }

    &.is-sticky {
        right: rem-calc(16);
        position: fixed;
        top: rem-calc(181);
        left: rem-calc(16);
        z-index: 66;

        @include mq-up(m) {
            left: rem-calc(28);
            right: rem-calc(28);
        }

        + .is-offset {
            margin-top: rem-calc(77);
        }
    }

    &__cb {
        position: absolute;
        z-index: -99;

        @include mq-up(l) {
            display: none;
        }

        &:checked {

            + #{$mark}clouds__trigger {
                &:before {

                    @include mq-to(l) {
                        border-top: none;
                        border-bottom: rem-calc(17) solid var(--cloud-trigger-color);
                    }
                }
            }

            ~ #{$mark}clouds__list {
                
                @include mq-to(l) {
                    display: block;
                    position: absolute;
                    top: rem-calc(40);
                    left: 0;
                    right: 0;
                    z-index: 66;
                }
            }
        }
    }

    &__trigger {
        background-color: var(--cloud-trigger-bgColor);
        display: block;
        color: var(--cloud-trigger-color);
        border: rem-calc(5) solid var(--cloud-trigger-border);
        text-transform: uppercase;
        padding: rem-calc(6.5 40 4.5 9);
        position: relative;
        @include cropText;
        @include buttonTypo;

        @include mq-up(l) {
            display: none;
        }

        &:before {
            content: '';
            display: inline-block;
            border-top: rem-calc(17) solid var(--cloud-trigger-color);
            border-left: rem-calc(10) solid transparent;
            border-right: rem-calc(10) solid transparent;
            position: absolute;
            right: rem-calc(9);
            top: rem-calc(6);
        }
    }

    &__list {
        padding: 0;
        margin: 0;
        list-style: none;

        @include mq-to(l) {
            height: rem-calc(200);
            overflow-y: auto;
            background-color: var(--cloud-trigger-bgColor);
            display: none;
        }
        
        @include mq-up(l) {
            display: flex;
            column-gap: rem-calc(24);
            flex-wrap: wrap;
        }
    }

    &__item {

        &.is-active {
            #{$mark}button {
                color: var(--color-active-filters);
                text-decoration: none;
            }
        }

        #{$mark}button {
            
            @include mq-to(l) {
                text-align: left;
                padding-inline: rem-calc(12);
            }
        }
    }

    + #{$mark}headline {
        margin-bottom: $margin-small-mob;

        @include mq-up(l) {
            margin-bottom: $margin-small-desk;
        }
    }
}