@use '../abstracts' as *;

#{$mark}button {
   display: block;
   border: rem-calc(5) solid var(--borderColor-button);
   padding: rem-calc(8 8 6);
   text-align: center;
   @include buttonTypo;

   @include mq-up(l) {
      display: inline-block;
   }

   &:not(&#{$mark}button--alt) {

      &:hover {
         border-color: transparent;
         background-color: var(--button-hover-bgColor);
         color: var(--button-hover-color);
      }
   }

   &--alt {
      padding: rem-calc(13 0 11);
      border: none;
      text-decoration: underline;

      &:hover {
         text-decoration: none;
         color: var(--button-alt-hover-color);
      }
   }

   &--group {
      display: flex;
      flex-direction: column;
      gap: $margin-fixed;
      margin-block: $margin-small-mob $margin-fixed;

      @include mq-up(l) {
         margin-top: $margin-fixed;
      }

      &-alt {
         flex-direction: row;
      }

      #{$mark}button {
         @include mq-up(l) {
            width: fit-content;
         }
      }
   }

   &--back {
      margin-block: rem-calc(-13 26);

      #{$mark}button {

         @include mq-to(l) {
            text-align: left;
         }
      }
   }
}