@use '../abstracts' as *;

#{$mark}list {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;

    &__item {
        width: 100%;

        &:not(:last-of-type) {
            margin-bottom: $margin-small-mob;

            @include mq-up(l) {
                margin-bottom: $margin-small-desk;
            }
        }
    }
}