@use '../abstracts' as *;

#{$mark}menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;

    &__link {
        line-height: 1;
    }

    &--i18n {
        margin: rem-calc(-12);
        text-transform: uppercase;        

        #{$mark}menu {

            &__link {
                padding: rem-calc(12);
                display: block;
            }
        }

        span {
            color: var(--color-active-i18n);
        }
        
        a:hover {
            color: var(--color-hover-i18n);
        }
    }

    &--main {
        margin: rem-calc(-12 -15);
        font-size: rem-calc(18);
        font-weight: 700;
        text-transform: lowercase;        

        #{$mark}menu {

            &__link {
                padding: rem-calc(12 15);
                display: block;

                @include mq-up(l) {
                    white-space: nowrap;
                }

                &:hover,
                &#{$mark}active {
                    color: var(--menu-active-color);
                }
            }
        }
    }

    &--service {
        margin: rem-calc(-12 -15);
        font-size: rem-calc(18);
        font-weight: 500;
        text-transform: lowercase;
        color: var(--menu-footer-color);
        
        @include mq-to(l) {
            flex-direction: column;
            row-gap: rem-calc(16);
        }
        
        #{$mark}menu {
            
            &__link {
                line-height: rem-calc(23);
                padding: rem-calc(12 15);
                display: block;

                @include mq-up(l) {
                    display: inline-block;
                }

                &:hover,
                &#{$mark}active {
                    color: var(--menu-footer-active-color);
                }
            }
        }
    }
}